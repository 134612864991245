import React, {useState, useEffect} from "react";

import "./lccBase.css";
import homeText from "./homeText.json";
import house1 from "../images/gallery/gallery8.jpeg";
import digging from "../images/other/digging.jpg";
import stock_house1 from "../images/other/house1.jpg";
import ssr from "../images/other/ssr.jpg";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import HomePage from "./HomePage";

export default function Home() {

  return (
    <>
      <div className="lcc-imgbox"></div>
      <img className="lcc-landing-img" src={house1} alt="house"/>      
      <div className="lcc-main-container">
        <Container maxWidth="xl"> 
          <div className="lcc-h1">A LIMITLESS HOME BUILDER</div>
          <div className="lcc-p">{homeText.home1}</div>
          <Grid container spacing={2} direction={{xs: "column", md:"row"}}>
            
            <Container maxWidth="xl">
              <Grid container direction={{xs: "column-reverse", md:"row"}}>
                <Grid item xs={8}>
                  <div className="lcc-h1">Custom Home Builder</div>
                  <div className="lcc-p">{homeText.home2}</div>
                </Grid>
                <Grid item xs={4} className="image-container">
                  <img src={digging} alt="digging"/>
                </Grid>
              </Grid>
            </Container>
            
            <Grid item xs={4} className="image-container">
            <img src={ssr} alt="sunset"/>
            </Grid>
            <Grid item xs={8}>
            <div className="lcc-h1">New Subdivision in Southern Maryland</div>
            <div className="lcc-p">{homeText.home3}</div>
            </Grid>
            
            <Container maxWidth="xl">
              <Grid container direction={{xs: "column-reverse", md:"row"}}>
            <Grid item xs={8}>
              <div className="lcc-h1">What We Bring You!</div>
                <ul className="lcc_ul">
                  <li className="lcc_sub_li">Customer-centered business model</li>
                  <li className="lcc_sub_li">An extensive list of proven contractors</li>
                  <li className="lcc_sub_li">Superior product at an excellent value</li>
                  <li className="lcc_sub_li">Quick home construction, 6-9 months post permitting</li>
                  <li className="lcc_sub_li">Strong relationships with local mortgage agents and banking institutions</li>
              </ul>
            </Grid>
            <Grid item xs={4} className="image-container">
            <div >
              <img className="image-container" src={stock_house1} alt="limitless stock house"/>
            </div>
            </Grid>
            </Grid>
            </Container>

            <Grid item xs={12}>
              <div></div>
            </Grid>
          </Grid>
        </Container>
      </div>
      
    </>
  );
}
