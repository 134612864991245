import React from "react";
import sunsetRidgeEntraceImg from "../images/other/sunset_ridge_entrance.jpg";
import sunsetRidgeSoldImg from "../images/other/soldlots2.png";
import lotText from "./lotsText.json";
import Container from '@mui/material/Container';

export default function SunsetRidge() {
  return (
    <>
    <div className="lcc-main-container">
      <Container maxWidth="xl">
        <h1 className="lcc-h1">Sunset Ridge</h1>
        <img src={sunsetRidgeEntraceImg}></img>
        <p className="lcc-p">{lotText.sunsetRidge1}</p>
        <p className="lcc-p">{lotText.sunsetRidge2}</p>
        <p className="lcc-p">{lotText.sunsetRidge3}</p>

        <h3 className="lcc-h3">Sunset Ridge Lot Map</h3>
        <img src={sunsetRidgeSoldImg} className="lcc-center-image"></img>
        <h3 className="lcc-h3">Remaining Available Lots</h3>
        <table>
          <tr>
            <th>Lot Number</th>
            <th>Price</th>
            <th>Acres</th>
          </tr>
          <tr>
            <td>Lot 7</td>
            <td>$129,000</td>
            <td>1.000</td>
          </tr>
          <tr>
            <td>Lot 8</td>
            <td>$129,000</td>
            <td>1.027</td>
          </tr>
          <tr>
            <td>Lot 13</td>
            <td>$195,000</td>
            <td>2.141</td>
          </tr>
          <tr>
            <td>Lot 18</td>
            <td>$139,000</td>
            <td>1.411</td>
          </tr>
          <tr>
            <td>Lot 19</td>
            <td>$139,000</td>
            <td>1.638</td>
          </tr>
        </table>    
        <p>&nbsp;</p>
      </Container>
    </div>
    </>
  );
}