import React from "react";

import "./lccBase.css";
import aboutText from "./aboutText.json";
import cbc from "../images/personal/cbc.png";
import mo from "../images/personal/Headshot.png";
import willis from "../images/personal/willis.JPEG";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

export default function About() {
  return (
    <>
      <div className="lcc-main-container">
        <Container maxWidth="xl">
          <div className="lcc-h1">{aboutText.aboutIntros}</div>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <div className="lcc-p">{aboutText.aboutIntros1}</div>
                <div className="lcc-p">{aboutText.aboutIntros2}</div>
              </Grid>
              <Grid item xs={4}>
                <img className="lcc-personal-image" src={mo} alt="mo"/>
              </Grid>
            </Grid>
            {/* <div className="lcc-p">{aboutText.aboutIntros2}</div> 
            <img className="lcc-personal-image" src={cbc} alt="cbc"/>
            <div className="lcc-p">{aboutText.aboutIntros3}</div>
            <img className="lcc-personal-image" src={willis} alt="willis"/>
            <div className="lcc-p">{aboutText.aboutIntros4}</div>*/}
          </Container>
      </div>
    </>
  );
}