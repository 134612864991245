import React from "react";
import sunsetRidgeEntraceImg from "../images/other/sunset_ridge_entrance.jpg";
import sunsetRidgeSoldImg from "../images/other/soldlots.png";
import buildYourOwnText from "./buildYourOwnText.json";
import Container from '@mui/material/Container';

export default function BuildYourOwn() {
  return (
    <>
    <div className="lcc-main-container">
      <Container maxWidth="xl">
      <h1 className="lcc-h1">Build on Your Own Lot</h1>
      <p className="lcc-p">{buildYourOwnText.byol1}</p>
      <p className="lcc-p">Here are some steps to help you navigate the process: </p>
      <ol className="lcc_ol">
        <li className="lcc_sub_li">{buildYourOwnText.byol2}</li>
        <li className="lcc_sub_li">{buildYourOwnText.byol3}</li>
        <ol className="lcc_sub_ol">
            <li className="lcc_sub_sub_li"><b>With a floorplan: </b>{buildYourOwnText.byol4}</li>
            <li className="lcc_sub_sub_li"><b>With a builder: </b>{buildYourOwnText.byol5}</li>
            <li className="lcc_sub_sub_li"><b>With an architect: </b>{buildYourOwnText.byol6}</li>
        </ol>
        <li className="lcc_sub_li">{buildYourOwnText.byol7}</li>
        <li className="lcc_sub_li">{buildYourOwnText.byol8}</li>
    </ol> 
    </Container>
    </div>
    </>
  );
}