import React from "react";
import sunsetRidgeEntraceImg from "../images/other/sunset_ridge_entrance.jpg";
import sunsetRidgeSoldImg from "../images/other/soldlots.png";
import lotText from "./lotsText.json";


export default function LexingtonPark() {
  return (
    <>
    <div className="lcc-main-container">
      <h1 className="lcc-h1">Lexington Park, MD</h1>
      <h2 className="lcc-h2">Boop</h2>  
    </div>
    </>
  );
}