import gallery1 from '../images/gallery/gallery1.jpeg'
import gallery2 from '../images/gallery/gallery2.jpeg'
import gallery3 from '../images/gallery/gallery3.jpeg'
import gallery4 from '../images/gallery/gallery4.jpeg'
import gallery5 from '../images/gallery/gallery5.jpeg'
import gallery6 from '../images/gallery/gallery6.jpeg'
import gallery7 from '../images/gallery/gallery7.jpeg'
import gallery8 from '../images/gallery/gallery8.jpeg'
import gallery9 from '../images/gallery/gallery9.jpeg'
import gallery10 from '../images/gallery/gallery10.jpeg'
import gallery11 from '../images/gallery/gallery11.jpeg'
import gallery12 from '../images/gallery/gallery12.jpeg'
import gallery13 from '../images/gallery/gallery13.jpeg'
import gallery14 from '../images/gallery/gallery14.jpeg'
import gallery15 from '../images/gallery/gallery15.jpeg'
import gallery16 from '../images/gallery/gallery16.jpeg'
import gallery17 from '../images/gallery/gallery17.jpeg'
import gallery18 from '../images/gallery/gallery18.jpeg'
import gallery19 from '../images/gallery/gallery19.jpeg'
import gallery20 from '../images/gallery/gallery20.jpeg'
import gallery22 from '../images/gallery/gallery22.jpeg'
import gallery25 from '../images/gallery/gallery25.jpeg'
import gallery28 from '../images/gallery/gallery28.jpeg'
import gallery29 from '../images/gallery/gallery29.jpeg'
import gallery30 from '../images/gallery/gallery30.jpeg'
import gallery31 from '../images/gallery/gallery31.jpeg'
import gallery32 from '../images/gallery/gallery32.jpeg'
import gallery33 from '../images/gallery/gallery33.jpeg'
import gallery34 from '../images/gallery/gallery34.jpeg'


export const galleryImages = [
  {
    src: gallery5,
    width: 40,
    height: 30
  },
  {
    src: gallery16,
    width: 29,
    height: 39
  },
    
    {
      src: gallery2,
      width: 35,
      height: 25
    },
    {
      src: gallery3,
      width: 39,
      height: 26
    },
    {
      src: gallery4,
      width: 37,
      height: 25
    },
    {
      src: gallery34,
      width: 39,
      height: 28
    },
    {
      src: gallery6,
      width: 39,
      height: 27
    },
    {
      src: gallery7,
      width: 39,
      height: 26
    },
    {
      src: gallery8,
      width: 45,
      height: 28
    },
    {
      src: gallery9,
      width: 29,
      height: 39
    },
    {
      src: gallery10,
      width: 38,
      height: 27
    },
    {
      src: gallery11,
      width: 27,
      height: 36
    },
    {
      src: gallery12,
      width: 30,
      height: 40
    },
    {
      src: gallery13,
      width: 40,
      height: 30
    },
    {
      src: gallery14,
      width: 40,
      height: 30
    },
    {
      src: gallery15,
      width: 40,
      height: 30
    },
    {
      src: gallery17,
      width: 37,
      height: 28
    },
    {
      src: gallery18,
      width: 38,
      height: 28
    },
    {
      src: gallery19,
      width: 30,
      height: 28
    },
    {
      src: gallery20,
      width: 40,
      height: 30
    },
    {
      src: gallery22,
      width: 40,
      height: 30
    },
    {
      src: gallery25,
      width: 40,
      height: 30
    },
    {
      src: gallery28,
      width: 40,
      height: 30
    },
    {
      src: gallery29,
      width: 30,
      height: 40
    },
    {
      src: gallery30,
      width: 30,
      height: 40
    },
    {
      src: gallery31,
      width: 40,
      height: 30
    },
    {
      src: gallery32,
      width: 40,
      height: 30
    },
    {
      src: gallery33,
      width: 40,
      height: 30
    },
    {
      src: gallery1,
      width: 25,
      height: 35
    }
  ];
  