import React from "react";
import Container from '@mui/material/Container';


import "./lccBase.css";

export default function Contact() {
  return (
    <>
      <div className="lcc-main-container-contact">
        <Container maxWidth="xl">
          <div className="lcc-h1">Contact Your Custom Home Builder Today</div>
          <div className="lcc-p">Let's connect!  We'd love to give you a tour of the public spaces and building lots in our Sunset Ridge subdivision.  We often have a custom home under construction so you can see our quality for yourself.</div>
          <div className="lcc-p-contact-info">Morgan Miller Worcester</div>
          <div className="lcc-p-contact-info">(240) 925-9606</div>
          <div className="lcc-p-contact-info">morgan.miller628@gmail.com</div>
          <div className="lcc-p-contact-info">23362 Morgan Court</div>
          <div className="lcc-p-contact-info">Hollywood, MD 20636</div>
        </Container>
      </div>
      
    </>
  );
}
