export const MenuItems = [
    {
      title: 'Sunset Ridge',
      path: '/sunsetridge',
      cName: 'dropdown-link'
    },
    {
      title: 'Build on your own Lot',
      path: '/buildyourown',
      cName: 'dropdown-link'
    }
  ];

/*
  {
    title: 'California, MD',
    path: '/california',
    cName: 'dropdown-link'
  },
  {
    title: 'Lexington Park, MD',
    path: '/lexingtonpark',
    cName: 'dropdown-link'
  },*/