import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import Dropdown from './Dropdown';
import Dropdown2 from './Dropdown2';
import logoBlack from '../images/logos/V2.png'
import { useContext } from 'react';

import { PageContext } from "../App.js";


function Navbar() {
  const [context, setContext] = useContext(PageContext);

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [dropdown, setDropdown] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [count, setCount] = useState(0);
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const scrollToTop = () => {
    /*window.scrollTo({
      top: 0
      /* you can also use 'auto' behaviour
         in place of 'smooth' 
    });*/
  }


  const onMouseEnter = () => {
    //if (window.innerWidth < 960) {
    //  setDropdown(false);
    //} else {
      setDropdown(true);
    //}
  };

  const onMouseLeave = () => {
    //if (window.innerWidth < 960) {
    //  setDropdown(false);
    //} else {
      setDropdown(false);
    //}
  };

  const onMouseEnter2 = () => {
    //if (window.innerWidth < 960) {
    //  setDropdown2(false);
    //} else {
      setDropdown2(true);
    //}
  };

  const onMouseLeave2 = () => {
    //if (window.innerWidth < 960) {
    //  setDropdown2(false);
    //} else {
      setDropdown2(false);
    //}
  };

  const [navBackground, setNavBackground] = useState('transparent');
  const [navText, setNavText] = useState('black');
  const [shadowBox, setShadowBox] = useState('');

  useEffect(() => {
    const pageReload = () => {
      if(context == "home" && scrollY <= 1000)
      {
        setNavBackground('transparent'); // Set nav background to transparent when user scrolls back up
        setNavText('black');
        setShadowBox('');
      }
      else{
        setNavBackground('white'); // Set nav background to green when user scrolls down
        setNavText('black');
        setShadowBox('0 4px 2px -2px gray');
      }
    };

    pageReload();
  }, [context, scrollY]);
  

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.pageYOffset);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  window.addEventListener("resize", showButton);
  return (
    <>
      <nav className="navbar" style={{ backgroundColor: navBackground , color: navText, boxShadow: shadowBox}}>
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={() => {scrollToTop(); setContext("home");}}>
            <img className="navbar-logo" src={navBackground == 'white' ? logoBlack : logoBlack}/>
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fa fa-times" : "fa fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link to="/" className="nav-links" onClick={() => {closeMobileMenu(); scrollToTop(); setContext("home");}}>
                Home
              </Link>
            </li>
            <li
            className='nav-item'
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            >
              <div                
                className="nav-links"
              >
                Available Lots&nbsp;<i className="fa fa-caret-down" />
              </div>
              {dropdown && <Dropdown />}
            </li>
            <li className="nav-item">
              <Link
                to="/gallery"
                className="nav-links"
                onClick={() => {closeMobileMenu(); scrollToTop(); setContext("gallery");}}
              >
                Gallery
              </Link>
            </li>

            <li
            className='nav-item'
            onMouseEnter={onMouseEnter2}
            onMouseLeave={onMouseLeave2}
            >
              <div                
                className="nav-links"
              >
                Why Limitless&nbsp;<i className="fa fa-caret-down" />
              </div>
              {dropdown2 && <Dropdown2 />}
            </li>

            {/*<li className="nav-item">
              <Link
                to="/about"
                className="nav-links"
                onClick={() => {closeMobileMenu(); scrollToTop(); setContext("about");}}
              >
                About
              </Link>
            </li>*/}
            <li className="nav-item">
              <Link
                to="/contact"
                className="nav-links"
                onClick={() => {closeMobileMenu(); scrollToTop(); setContext("contact");}}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
