import React from "react";
import sunsetRidgeEntraceImg from "../images/other/sunset_ridge_entrance.jpg";
import sunsetRidgeSoldImg from "../images/other/soldlots.png";
import californiaText from "./californiaText.json";


export default function California() {
  return (
    <>
    <div className="lcc-main-container">
      <h1 className="lcc-h1">California, MD</h1>
      <h2 className="lcc-h2">Combs Way</h2>  
      <p className="lcc-p">{californiaText.california1}</p>
    </div>
    </>
  );
}