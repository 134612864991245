import React from "react";
import Gallery from "react-photo-gallery";
import { galleryImages } from "../components/GalleryImages";
import "./lccBase.css";

export default function HouseGallery() {

  const BasicRows = () => <Gallery photos={galleryImages} />;
  return (
    <>
      <div className="lcc-main-container">
        <BasicRows></BasicRows>
      </div>
    </>
  );
}