import React from "react";
import reasonsForCustomText from "./reasonsForCustomText.json";
import Container from '@mui/material/Container';

export default function ReasonsForCustom() {
  return (
    <>
    <div className="lcc-main-container">
      <Container maxWidth="xl">
      <h1 className="lcc-h1">5 Reasons to Build Custom</h1>
      
      <ol className="lcc_ol">
        <li className="lcc_sub_li">{reasonsForCustomText.rfc1}</li>
          <ul className="lcc_ul">
            <li className="lcc_sub_li">{reasonsForCustomText.rfc2}</li>
          </ul>

        <li className="lcc_sub_li">{reasonsForCustomText.rfc3}</li>
          <ul className="lcc_ul">
            <li className="lcc_sub_li">{reasonsForCustomText.rfc4}</li>
          </ul>

        <li className="lcc_sub_li">{reasonsForCustomText.rfc5}</li>
          <ul className="lcc_ul">
            <li className="lcc_sub_li">{reasonsForCustomText.rfc6}</li>
          </ul>
        
        <li className="lcc_sub_li">{reasonsForCustomText.rfc7}</li>
          <ul className="lcc_ul">
            <li className="lcc_sub_li">{reasonsForCustomText.rfc8}</li>
            <li className="lcc_sub_li">{reasonsForCustomText.rfc10}</li>
          </ul>

        <li className="lcc_sub_li">{reasonsForCustomText.rfc9}</li>
        
    </ol> 
    </Container>
    </div>
    </>
  );
}