import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Lots from "./pages/Lots";
import HouseGallery from "./pages/HouseGallery";
import Footer from "./components/Footer";

import "./App.css";
import SunsetRidge from "./pages/SunsetRidge";
import California from "./pages/California";
import LexingtonPark from "./pages/LexingtonPark";
import BuildYourOwn from "./pages/BuildYourOwn";
import ReaonsForCustom from "./pages/ReasonsForCustom";
export const PageContext = React.createContext('page');

function App() {
  const [context, setContext] = useState("home");
  return (
    <div className="App">
      <PageContext.Provider value={[context, setContext]}>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/about" element={<About />}/>
            <Route path="/contact" element={<Contact />}/>
            <Route path="/gallery" element={<HouseGallery />}/>
            <Route path="/sunsetridge" element={<SunsetRidge />}/>
            <Route path="/california" element={<California />}/>
            <Route path="/lexingtonpark" element={<LexingtonPark />}/>
            <Route path="/buildyourown" element={<BuildYourOwn />}/>
            <Route path="/buildcustom" element={<ReaonsForCustom />}/>
          </Routes>
        </BrowserRouter>
        <Footer  />
      </PageContext.Provider>
    </div>
  );
}

export default App;
