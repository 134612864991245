import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@mui/material/Box';
import Link from '@material-ui/core/Link';
import Grid from '@mui/material/Grid';
import "../pages/lccBase.css";
import { makeStyles } from '@material-ui/core/styles';
import logo from '../images/logos/white_cropped.png'


export default function Footer() {

  const useStyles = makeStyles(theme => ({
    mycolor: {
    backgroundColor: 'black'
    }
  }));
/*style={{position:"fixed", bottom:0, left: 0, right: 0,}}*/
  return (
    <footer >
      <Box
        px={{ xs: 3, sm: 10 }}
        py={{ xs: 5, sm: 10 }}
        bgcolor="#073100"
        color="white"
      >
        <Container maxWidth="lg">
          <Grid container spacing={5}>
            <Grid item xs={12} sm={4}>
              <Box ><img className="footer-logo" src={logo}/></Box>
              
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box borderBottom={1}>Contact</Box>
              <Box>
                  <i className="fa fa-phone"/>
                  &nbsp;(240)-925-9606
              </Box>
              <Box>
                  <i className="fa fa-envelope"/>
                  &nbsp;morgan.miller628@gmail.com
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box borderBottom={1}>Socials</Box>
              <Box>
                <Link target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/LimitlessCustomConstructionLLC/" color="inherit">
                <i className="fa fa-facebook"/>
                  &nbsp;Facebook
                </Link>
              </Box>
              <Box>
                <Link target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/limitlesscustomconstruction/?igshid=MzRlODBiNWFlZA%3D%3D" color="inherit">
                <i className="fa fa-instagram"/>
                  &nbsp;Instagram
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Box textAlign="center" pt={{ xs: 5, sm: 10 }} pb={{ xs: 5, sm: 0 }} bgcolor="#073100">
            Limitless Custom Construction &reg; {new Date().getFullYear()} 
          </Box>
        </Container>
      </Box>
    </footer>
  );
}