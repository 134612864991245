export const MenuItems = [
    {
      title: 'About',
      path: '/about',
      cName: 'dropdown-link'
    },
    {
      title: '5 Reasons to Build Custom',
      path: '/buildcustom',
      cName: 'dropdown-link'
    }
  ];

/*
  {
    title: 'California, MD',
    path: '/california',
    cName: 'dropdown-link'
  },
  {
    title: 'Lexington Park, MD',
    path: '/lexingtonpark',
    cName: 'dropdown-link'
  },*/